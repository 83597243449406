<template>
  <div>
    <div class="winex-wine-detail-wrap" v-if="!isNotOwner">
      <div class="winex-wine-detail-top-info-wrap">
        <div class="winex-wine-image-wrap" :style="{backgroundImage:`url(${wineInfo.image})`}">

        </div>
        <div class="winex-wine-info-wrap flex flex-col gap-[20px]">
          <!-- 북마크 아이콘 -->
          <!--<div class="bookmark-icon" @click="gettingReady"></div>-->

          <!-- 와인 기본 정보 -->
          <div class="flex flex-col gap-[8px] py-[20px] border-b border-light">
            <h5 class="text-[16px] text-darkDark leading-[28px] m-0">{{wineInfo.brand}}</h5>
            <h4 class="text-[24px] leading-[44px] font-[Suit-Medium] m-0">{{ redeemInfo.nft_name }} </h4>
          </div>

          <div class="flex">
            <div class="flex flex-wrap gap-x-[20px] gap-y-[4px] mobile:gap-x-[0]">
              <WineInfo title="Type" :subCategory="getSubCategoryInfo(1)" />
              <WineInfo title="Grape" :subCategory="getSubCategoryInfo(18)" />
              <WineInfo title="Format" :subCategory="getSubCategoryInfo(3)" />
              <WineInfo title="Case*Bottle" :subCategory="getSubCategoryInfo(17)" />
              <WineInfo title="Winery" :subCategory="getSubCategoryInfo(16)" />
              <WineInfo title="Country" :subCategory="getSubCategoryInfo(2)" />
              <WineInfo title="Region" :subCategory="getSubCategoryInfo(19)" />
              <WineInfo title="SubRegion1" :subCategory="getSubCategoryInfo(20)" />
              <WineInfo title="SubRegion2" :subCategory="getSubCategoryInfo(21)" />
              <WineInfo title="SubRegion3" :subCategory="getSubCategoryInfo(22)" />
              <WineInfo title="Grade" :subCategory="getSubCategoryInfo(4)" />
              <WineInfo title="Vineyard" :subCategory="getSubCategoryInfo(23)" />
              <WineInfo title="Vintage" :subCategory="getSubCategoryInfo(24)" />
              <WineInfo title="Winex Minting Vintage(WMV)" :subCategory="getSubCategoryInfo(25)" />
              <WineInfo title="Minter" :subCategory="{name: wineInfo.propertyList.minter}" />
            </div>
          </div>


          <div class="nft-link-info-wrap">
            <div class="nft-link-col">
              <div class="nft-link-title">Token ID</div>
              <div class="nft-link-content"><a :href="`https://polygonscan.com/token/${address}?a=${tokenId}`"
                                               target="_blank">{{ tokenId }}</a></div>
            </div>
            <div class="nft-link-col">
              <div class="nft-link-title">Contract Address</div>
              <div class="nft-link-content"><a :href="`https://polygonscan.com/token/${address}`"
                                               target="_blank">{{ address }}</a></div>
            </div>
          </div>

          <div class="flex flex-col redeem-content-wrap bg-[#FAFAFA] px-[20px] py-[20px] gap-[20px]">
            <table>
              <tr>
                <th>리딤 요청</th>
                <td>{{ getRedeemCreatedAtDate() }} <span class="text-darkLight">({{getRedeemCreatedAtTime()}})</span></td>
              </tr>
<!--              <tr>
                <th>배송 국가</th>
                <td>{{ redeemInfo.country || '-' }}</td>
              </tr>-->
              <tr>
                <th>배송 방법</th>
                <td>{{ redeemInfo.shipping_method_text }}</td>
              </tr>
              <tr>
                <th>상세 주소</th>
                <td>({{ postcode }}){{ defaultAddress }}</td>
              </tr>
              <tr>
                <th>성함</th>
                <td>{{ firstName }} {{ lastName }}</td>
              </tr>
              <tr>
                <th>연락처</th>
                <td>{{ phone }}</td>
              </tr>
              <tr>
                <th>구매 가격</th>
                <td>
                  <span class="usdc-data" v-if="wineInfo.price">{{ $numberFormat(wineInfo.price.coin) }} <span>({{ $numberFormat(wineInfo.price.won).split('.')[0] }}원)</span></span>
                </td>
              </tr>
<!--              <tr>
                <th>배송비</th>
                <td>
                  <span class="text-[16px] font-[Suit-Bold]">0원</span>
                </td>
              </tr>-->
            </table>

            <div class="flex items-start justify-center gap-[76px] mobile:gap-[12px] relative w-fit m-auto">
              <ShippingProcess
                  title="배송 요청"
                  :image="require('@/assets/images/icon/icon-shipping-request.png')"
                  :active="Number(redeemInfo.shipping_status) > 0"
              />
              <div class="h-[60px] flex items-center mobile:h-[40px] absolute left-[60px] mobile:left-[50px]">
                <div
                    class="h-[2px] w-[76px] mobile:w-[32px]"
                    :style="{background: Number(redeemInfo.shipping_status) > 1 ? '#A8221A' : '#CBCBCB'}"
                />
              </div>
              <ShippingProcess
                  title="요청 승인"
                  :image="require('@/assets/images/icon/icon-request-accept.png')"
                  :active="Number(redeemInfo.shipping_status) > 1"
              />
              <div class="h-[60px] flex items-center mobile:h-[40px] absolute left-[196px] mobile:left-[122px]">
                <div
                    class="h-[2px] w-[76px] mobile:w-[32px]"
                    :style="{background: Number(redeemInfo.shipping_status) > 2 ? '#A8221A' : '#CBCBCB'}"
                />
              </div>
              <ShippingProcess
                  title="배송 중"
                  :image="require('@/assets/images/icon/icon-shipping.png')"
                  :active="Number(redeemInfo.shipping_status) > 2"
              />
              <div class="h-[60px] flex items-center mobile:h-[40px] absolute left-[332px] mobile:left-[194px]">
                <div
                    class="h-[2px] w-[76px] mobile:w-[32px]"
                    :style="{background: Number(redeemInfo.shipping_status) > 3 ? '#A8221A' : '#CBCBCB'}"
                />
              </div>
              <ShippingProcess
                  title="배송 완료"
                  :image="require('@/assets/images/icon/icon-shipping-complete.png')"
                  :active="Number(redeemInfo.shipping_status) > 3"
              />

            </div>

            <ul class="!list-disc px-[20px] !list-inside">
              <li class="text-[14px] text-lightDark leading-[24px]">배송 요청 승인 후 취소할 수 없습니다.</li>
            </ul>
          </div>
          <div class="flex flex-col gap-[20px] mobile:w-fit w-full mobile:m-auto" v-if="redeemInfo.shipping_status === '01'">
            <div class="border border-lightLightDark h-[52px] leading-[52px] text-center text-[16px] text-darkDark rounded-[5px] cursor-pointer mobile:w-[214px] w-full" @click="$router.push(`/redeem/edit/${$route.params.no}`)">배송지 수정</div>
          </div>

        </div>
      </div>
      <div class="wine-info-wrap">
        <div class="wine-info-item">
          <h3 class="wine-detail-title">Description</h3>
          <div class="wine-description" v-html="wineInfo.description"></div>
        </div>

      </div>
    </div>

    <AlertModal
        :title="alertTitle"
        :content="alertContent"
        :pc-width="400"
        :button-class="'primary-btn'"
        :is-open="defaultAlertModalOpen"
        :confirm-click-btn="closeAlertModal"></AlertModal>

    <AlertModal
        title="오류"
        content="본인소유의 리딤이 아닙니다."
        :pc-width="400"
        :button-class="'primary-btn'"
        :is-open="isShowNotOwnerAlert"
        :confirm-click-btn="closeNotOwnerAlertModal"></AlertModal>

  </div>
</template>

<script>
// Property 출력시 테스트 데이터와 실제 노출 되는 데이터가 조금 상이 합니다.
// Ntf Detail 715번째 줄 this.wineInfo.propertyList = {...this.wineInfo.propertyList, ...info.properties} 참조해 주셔서
// NFT 정보를 가져온 이후 데이터 통합시에 위와 같이 처리해 주시면 될 것 같습니다.
import getContractApi from "../../api/contract";

import ShippingProcess from "@/components/redeem/ShippingProcess";
import {mapState} from "vuex";
import WineInfo from "@/components/redeem/WineInfo";
import AlertModal from "@/components/modals/AlertModal";

export default {
  name: "Complete",
  components: {AlertModal, WineInfo, ShippingProcess},
  computed: {
    ...mapState('redeem', ['redeemInfo'])
  },
  data() {
    return {
      contract: null,
      wineInfo: {
        brand: 'WINERY',
        name: "",
        image: '',
        propertyList: {
          type:"",
          grape:"",
          winery:"",
          format:"",
          "case * bottle":"",
          country:"",
          region:"",
          subregion_1:"",
          subregion_2:"",
          subregion_3:"",
          grade:"",
          vineyard:"",
          vintage:"",
          wmv:0,
          minter:""

        },
        description: "",
        price: null
      },
      tokenId: "",
      address: "",
      wineCharacteristicOpen: true,
      postcode: "",
      lastName: "",
      firstName: "",
      defaultAddress: "",
      detailAddress: "",
      recipient: "",
      phone: "",
      market: null,

      redeemModelOpenCheck:false,

      defaultAlertModalOpen:false,
      alertTitle: '알림',
      alertContent:'준비중 입니다.',

      isNotOwner: false,
      isShowNotOwnerAlert: false,
    }
  },
  created() {
    let self = this
    this.contract = getContractApi();
    this.market = this.contract.getWineMarket()
    this.$store.dispatch('redeem/getReedem', {redeem_no: this.$route.params.no}).then((data) => {
      if(self.$store.state.user.walletAddress !== data.user_key){
        self.isShowNotOwnerAlert = true;
        self.isNotOwner = true;
        return false;
      }
      self.wineInfo.name = data.nft_name
      self.postcode = data.postcode
      self.recipient = data.recipient
      self.defaultAddress = data.default_address
      self.detailAddress = data.detail_address
      self.phone = data.phone_number
      self.tokenId = data.nft_key
      self.address = data.address
      self.lastName = data.last_name
      self.firstName = data.first_name


      // 위 data의 NFT_KEY + user_key값 으로 본인 소유의 와인인지 확인. 본인 소유의 와인이 아니라면 오류 반환
      // 본인 소유의 와인이라면 해당 NFT 정보 가져와서 위 상세 정보 반환하여 화면에 노출
      self.market.fetchItemInfoByTokenId(data.nft_key).then((data) => {
        self.wineInfo.price = data.price;
        self.wineInfo.image = data.image
        self.wineInfo.name = data.name
        self.wineInfo.propertyList = {...self.wineInfo.propertyList, ...data.properties}
        self.wineInfo.description = `
                   <div class="sc-1xf18x6-0 haVRLx item--description-text">
                        <div class="sc-edvquz-0 fWQpLM">
                            <p>${data.description.replaceAll('\n', "<br/>")}</p>
                        </div>
                    </div>
                `;
        self.wineInfo.minter = data.minter
        if (self.wineInfo.minter) {
          self.$store.dispatch('creator/getCreator', {wallet_address: self.wineInfo.minter}).then((data) => {
            self.wineInfo.propertyList.minter = data.nickname
          })
        }
      })
    }).catch(() => {
      self.openAlertModal('오류', '리딤 정보를 가져오는 중 오류가 발생하였습니다.')
    })

  },
  methods: {
    gettingReady() {
      alert('준비중 입니다.')
    },
    toggleCharacter() {
      this.wineCharacteristicOpen = !this.wineCharacteristicOpen
    },
    changePropertyFirstUpper(text) {
      return text.replaceAll('_', ' ').split(' ').map(v => {
        return v.charAt(0).toUpperCase() + v.slice(1);
      }).join(' ');
    },
    getSubCategoryInfo(mainCategoryNo){
      // const propertyList = this.redeemInfo.wine_nft_properties;
      let subCategory = null;
      /*
      propertyList?.forEach((property) => {
        if(property.main_category_no === mainCategoryNo){
          subCategory = {
            name: property.filter_sub_categories.filter_sub_category_name,
            categoryNo: property.sub_category_no
          };
        }
      })
      */
      if(subCategory == null){
        switch (mainCategoryNo){
          case 1:
            subCategory = {name: this.wineInfo.propertyList.type};
            break;
          case 2:
            subCategory = {name: this.wineInfo.propertyList.country};
            break;
          case 3:
            subCategory = {name: this.wineInfo.propertyList.format};
            break;
          case 4:
            subCategory = {name: this.wineInfo.propertyList.grade};
            break;
          case 16:
            subCategory = {name: this.wineInfo.propertyList.winery};
            break;
          case 17:
            subCategory = {name: this.wineInfo.propertyList["case * bottle"]};
            break;
          case 18:
            subCategory = {name: this.wineInfo.propertyList.grape};
            break;
          case 19:
            subCategory = {name: this.wineInfo.propertyList.region};
            break;
          case 20:
            subCategory = {name: this.wineInfo.propertyList.subregion_1};
            break;
          case 21:
            subCategory = {name: this.wineInfo.propertyList.subregion_2};
            break;
          case 22:
            subCategory = {name: this.wineInfo.propertyList.subregion_3};
            break;
          case 23:
            subCategory = {name: this.wineInfo.propertyList.vineyard};
            break;
          case 24:
            subCategory = {name: this.wineInfo.propertyList.vintage};
            break;
          case 25:
            subCategory = {name: this.wineInfo.propertyList.wmv};
            break;
        }
      }
      return subCategory;
    },
    getRedeemCreatedAtDate(){
      const createdAt = new Date(this.redeemInfo.created_at);

      const year = createdAt.getFullYear();
      const month = createdAt.getMonth()+1 < 10 ? `0${createdAt.getMonth()+1}` : createdAt.getMonth()+1;
      const date = createdAt.getDate() < 10 ? `0${createdAt.getDate()}` : createdAt.getDate();

      return `${year}.${month}.${date}`
    },
    getRedeemCreatedAtTime(){
      const createdAt = new Date(this.redeemInfo.created_at);


      const hour = createdAt.getHours() < 10 ? `0${createdAt.getHours()}` : createdAt.getHours();
      const min = createdAt.getMinutes() < 10 ? `0${createdAt.getMinutes()}` : createdAt.getMinutes();
      const sec = createdAt.getSeconds() < 10 ? `0${createdAt.getSeconds()}` : createdAt.getSeconds();

      return `${hour}:${min}:${sec}`
    },
    closeAlertModal() {
      this.defaultAlertModalOpen = false
    },


    openAlertModal(title, content) {
      this.alertTitle = title
      this.alertContent = content
      this.defaultAlertModalOpen = true
    },
    closeNotOwnerAlertModal(){
      this.isShowNotOwnerAlert = false;
      this.$router.go(-1);
    }
  },
  mounted(){
    const _this = this;
    this.contract.getWineMarket().getUserAddress().then(data => {
      if(data){
        _this.$store.state.user.walletAddress = data;
      }
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/nft-detail.scss";

.winex-wine-detail-wrap {
  padding: 40px 0 140px 0;
  @media (max-width: 1199px) {
    padding: 40px 16px;
    max-width: 480px;
  }

  .usdc-data {
    background-position: center left;
    background-repeat: no-repeat;
    background-image: url(~@/assets/images/icon/usdc-icon.png);
    background-size: 20px 20px;
    padding-left: 24px;
  }

  .redeem-content-wrap {

    table {
      th {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: $dark_light;
        width: 80px;
        vertical-align: top;
        padding-bottom: 4px;
      }

      td {

        padding-bottom: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: $dark;
        vertical-align: top;

        .usdc-data {
          color: #000;
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;

          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            margin-left: 4px;
            color: $dark_light;
          }
        }
      }
    }
  }
}

</style>
